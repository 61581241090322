import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

import commonConstants from '../constants/common.constant';
import { getUserInfoAPI } from '../hooks/Auth/authAPI';
import { login, setSilentLoading } from '../redux/slices/authSlice';
import store from '../redux/store';
import History from './history.util';

interface MobileSession {
  idToken: {
    jwtToken: string;
  };
  refreshToken: string;
  accessToken: {
    jwtToken: string;
    payload: { username: string; email: string };
  };
}

(window as any).mobileLogin = async function (mobileSession: MobileSession) {
  try {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams: any, prop: any) => searchParams.get(prop),
    });
    // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
    const cartToken = params?.[commonConstants.TOKEN]; // "some_value"
    if (!!cartToken && window) {
      window.localStorage.setItem(
        commonConstants.LOCAL_STORAGE_KEY.CART_TOKEN,
        cartToken,
      );
    }
    if (
      store.getState().auth.user?.email ===
        mobileSession.accessToken.payload.email &&
      store.getState().auth.isSilentLogin
    ) {
      return;
    }
    store.dispatch(setSilentLoading(true));
    const localSession = new CognitoUserSession({
      IdToken: new CognitoIdToken({ IdToken: mobileSession.idToken.jwtToken }),
      RefreshToken: new CognitoRefreshToken({
        RefreshToken: mobileSession.refreshToken,
      }),
      AccessToken: new CognitoAccessToken({
        AccessToken: mobileSession.accessToken.jwtToken,
      }),
    });

    const localUser = new CognitoUser({
      Username: mobileSession.accessToken.payload.username,
      Pool: (Auth as any).userPool,
      Storage: (Auth as any).userPool.storage,
    });
    localUser.setSignInUserSession(localSession);

    // this seems like a hack
    Auth.currentCredentials = async () => localSession as any;
    await Auth.currentSession();
    const response = await getUserInfoAPI(
      mobileSession.accessToken.payload.email,
    );
    store.dispatch(login({ user: response?.data, isSilentLogin: true }));
    const queryString = window.location.search;
    if (!response?.data?.checkoutInfo?.shippingInfo) {
      History.push(`/address-detail${queryString}`);
    } else if (!response?.data?.checkoutInfo?.paymentInfo) {
      History.push(`/payment-detail${queryString}}`);
    } else {
      History.push(`/summary${queryString}`);
    }
  } catch (err) {
    console.log(err);
  } finally {
    store.dispatch(setSilentLoading(false));
  }
};
