import { useSelector } from 'react-redux';
import commonConstants from '../../constants/common.constant';
import { getIsSilentLogin } from '../../redux/selectors/auth.selector';

export const useIsInIFrame = () => {
  const isSilentLogin = useSelector(getIsSilentLogin);
  const marginTopValueDependOnIFrame = isSilentLogin
    ? commonConstants.MARGIN_TOP.WITHOUT_IREFER_LOGO
    : commonConstants.MARGIN_TOP.WITH_IREFER_LOGO;
  return { isInIFrame: isSilentLogin, marginTopValueDependOnIFrame };
};
