import { useMemo } from 'react';
import { Box, Card, CardMedia, Grid, Stack } from '@mui/material';
import { StackHorizontal } from '../Styled/CommonStyled';
import {
  Text12Weight500Gray,
  Text12Weight600,
  Text16Weight500,
  Text16Weight500Gray,
} from '../Styled/TypographyStyled';
import formatUtil from '../../../utils/format.util';
import CircularProgress from '@mui/material/CircularProgress';

type ItemListProps = {
  cartData: CartType;
  isLoading: boolean;
};

const ItemList = ({ cartData, isLoading }: ItemListProps) => {
  const formattedData = useMemo(() => {
    if (cartData) {
      return {
        items:
          cartData?.items?.map((item) => ({
            id: item?.prod_id ?? '--',
            title: item?.prod_name ?? '--',
            image: item?.img_url ?? '--',
            price: item?.total
              ? formatUtil.formatCurrency(+item.total, cartData?.currency)
              : '--',
            quantity: item?.qty ?? '--',
          })) ?? [],
        subTotal: cartData?.subtotal
          ? formatUtil.formatCurrency(+cartData?.subtotal, cartData?.currency)
          : '--',
        total: cartData?.amount
          ? formatUtil.formatCurrency(+cartData.amount, cartData?.currency)
          : '--',
        deliveryFee: `${formatUtil.formatCurrency(
          +cartData?.shipping_total ?? 0,
          cartData?.currency,
        )}`,
        tax: `${formatUtil.formatCurrency(
          +cartData?.tax ?? 0,
          cartData?.currency,
        )}`,
        arrivalTime: '',
      };
    }
    return null;
  }, [cartData]);
  return (
    <Box mt="30px">
      {formattedData ? (
        <Box>
          {formattedData.items.map((item: any) => (
            <Grid container key={item.id} mb={2}>
              <Grid item xs={5}>
                <Card sx={{ boxShadow: 'none', borderRadius: '5px' }}>
                  <CardMedia
                    component="img"
                    width="100%"
                    image={item.image}
                    alt={`image-${item.id}`}
                  />
                </Card>
              </Grid>
              <Grid item xs={7}>
                <Stack ml="20px" gap={0.2}>
                  <Text12Weight600>{item.title}</Text12Weight600>
                  {/* <Text12Weight500Gray>{item.category}</Text12Weight500Gray> */}
                  {/* <Text12Weight500Gray>{item.type}</Text12Weight500Gray> */}
                  {/* <Text12Weight500Gray>{item.size}</Text12Weight500Gray> */}
                  <Text12Weight500Gray>{`Quantity ${item.quantity}`}</Text12Weight500Gray>
                  <Text12Weight500Gray>{item.price}</Text12Weight500Gray>
                </Stack>
              </Grid>
            </Grid>
          ))}
          <StackHorizontal mt="20px">
            <Text16Weight500Gray>Sub Total</Text16Weight500Gray>
            <Text16Weight500Gray>{formattedData.subTotal}</Text16Weight500Gray>
          </StackHorizontal>
          <StackHorizontal>
            <Text16Weight500Gray>Delivery Fee</Text16Weight500Gray>
            <Text16Weight500Gray>
              {formattedData.deliveryFee}
            </Text16Weight500Gray>
          </StackHorizontal>
          {/* <StackHorizontal>
            <Text16Weight500Gray>Tax</Text16Weight500Gray>
            <Text16Weight500Gray>{formattedData.tax}</Text16Weight500Gray>
          </StackHorizontal> */}
          <StackHorizontal>
            <Text16Weight500>Total (Tax Incl.)</Text16Weight500>
            <Text16Weight500>{formattedData.total}</Text16Weight500>
          </StackHorizontal>
          {formattedData.arrivalTime ? (
            <StackHorizontal>
              <Text16Weight500 sx={{ textTransform: 'uppercase' }}>
                {formattedData.arrivalTime}
              </Text16Weight500>
            </StackHorizontal>
          ) : null}
        </Box>
      ) : (
        <Stack minHeight="50px" alignItems="center" justifyContent="center">
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Text16Weight500>Your cart is empty</Text16Weight500>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default ItemList;
