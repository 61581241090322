import React from 'react';
import { Outlet } from 'react-router-dom';
import { useGetCartDetails } from '../../hooks/Cart/useCart';
import SummaryDrawer from '../../pages/Summary/SummaryDrawer';
import { useIsInIFrame } from '../../hooks/Common/useIsIFrame';
import { CircularProgress, Stack } from '@mui/material';

const LayoutWithSummaryDrawer = () => {
  const { marginTopValueDependOnIFrame } = useIsInIFrame();
  const { data: cartDetailsData, isFetching: isFetchingCartData } =
    useGetCartDetails();
  return (
    <>
      <SummaryDrawer
        cartData={cartDetailsData!}
        isFetchingCartData={isFetchingCartData}
      />
      <Stack
        sx={{
          marginTop: marginTopValueDependOnIFrame,
          paddingBottom: '4rem',
          pt: 0,
        }}
      >
        {isFetchingCartData ? (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        ) : (
          <Outlet context={{ cartDetailsData, isFetchingCartData }} />
        )}
      </Stack>
    </>
  );
};

export default LayoutWithSummaryDrawer;
