import { Container, Paper } from '@mui/material';
import { ReactNode } from 'react';

type BottomNavigationSectionProps = {
  children: ReactNode;
  isBottom?: boolean;
};

const StickyNavigationSection = ({
  children,
  isBottom = true,
}: BottomNavigationSectionProps) => {
  const customStyled = isBottom ? { bottom: 0 } : { top: 0 };

  return (
    <Paper
      sx={{ zIndex: 9, position: 'fixed', left: 0, right: 0, ...customStyled }}
      elevation={0}
    >
      <Container maxWidth="sm">{children}</Container>
    </Paper>
  );
};

export default StickyNavigationSection;
