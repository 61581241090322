import { CircularProgress } from '@mui/material';
import { CenterBox } from '../Styled/CommonStyled';

export default function CenterLoader() {
  return (
    <CenterBox>
      <CircularProgress />
    </CenterBox>
  );
}
