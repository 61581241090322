import { API, Auth } from 'aws-amplify';

export const callAPIPost = async (body: any, apiName: string, path: string) => {
  const accessToken = await getAuthToken();
  return API.post(apiName, path, {
    body,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const callAPIGet = async (
  apiName: string,
  path: string,
  params: any,
) => {
  const accessToken = await getAuthToken();
  return API.get(apiName, path, {
    params,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getAuthToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.error(error);
    return null;
  }
};
