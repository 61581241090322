import { useMutation, useQuery } from '@tanstack/react-query';
import { ShippingMethodState } from '../../redux/slices/shippingMethodSlice';
import getQueryKey from '../../utils/get-query-key';
import useAxios from '../Common/useAxios';
import httpStatusCode from '../../constants/http-status-code.constant';
import { useStripe } from '@stripe/react-stripe-js';

function getRawQueryParam(paramName: string) {
  const url = window.location.href;
  const queryString = url.split('?')[1];
  if (!queryString) {
    return null;
  }

  const params = queryString.split('&');
  for (let param of params) {
    if (param.startsWith(paramName + '=')) {
      return param.substring(paramName.length + 1);
    }
  }

  return null;
}

export type CardDataType = {
  shipping: {
    shipping_fee: number;
    shipping_method: {
      price: {
        currencyCode: string;
      };
    };
    estimated_date: string;
  };
  shipping_method: ShippingMethodState[];
  cart: {
    rec_id: string;
    ireferal_code: string;
    currency: string;
    item_count: number;
    items: {
      id: string;
      title: string;
      image: string;
      final_price: number;
      quantity: number;
    }[];
  };
  sub_total: number;
  shipping_tax: number;
  tax: number;
  total: number;
};

export const useGetCartDetails = () => {
  const referral_token = getRawQueryParam('referral_token');
  const p = getRawQueryParam('p');
  const axios = useAxios();
  return useQuery({
    queryKey: getQueryKey.getCartDetailsQueryKey({}),
    queryFn: async () => {
      const resDecryptDataPromise = axios.post('/orders/decrypt', {
        referral_token,
        p,
      });
      const resStripeAccountPromise = axios.post('/stripe/get-vendor-account', {
        referral_token,
      });
      const [resDecryptData, resStripeAccount] = await Promise.all([
        resDecryptDataPromise,
        resStripeAccountPromise,
      ]);
      if (
        resDecryptData?.status === httpStatusCode.CREATED &&
        resStripeAccount?.status === httpStatusCode.CREATED
      ) {
        return {
          ...resDecryptData?.data,
          stripeAccount: resStripeAccount?.data?.stripeAccountId,
        } as CartDataResponse;
      }
      throw new Error('Unable to get cart details.');
    },
    enabled: !!referral_token && !!p,
    retry: 2,
  });
};

export const useGetSetupIntent = () => {
  const referral_token = getRawQueryParam('referral_token');
  const p = getRawQueryParam('p');
  const axios = useAxios();
  return useQuery({
    enabled: !!referral_token && !!p,
    queryKey: getQueryKey.getStripeClientSecretQueryKey({ p, referral_token }),
    queryFn: async () => {
      const response = await axios.post('/stripe/create-setup-intent', {
        referral_token,
        p,
      });
      if (response.data?.clientSecret && response.data?.customerId) {
        return {
          clientSecret: response.data?.clientSecret as string,
          customerId: response.data?.customerId as string,
        };
      }
      throw new Error('Unable to get client secret');
    },
  });
};

export const useConfirmPayment = () => {
  const stripe = useStripe();
  const axios = useAxios();
  const referral_token = getRawQueryParam('referral_token');
  const p = getRawQueryParam('p');
  return useMutation({
    mutationFn: async ({
      paymentMethodId,
      customerId,
    }: {
      paymentMethodId: string;
      customerId: string;
    }) => {
      const response = await axios.post('/stripe/create-payment-intent', {
        referral_token,
        p,
        paymentMethodId,
        customerId,
      });
      if (response.data?.clientSecret) {
        const result = await stripe!.confirmCardPayment(
          response.data?.clientSecret,
          undefined,
          {
            handleActions: true,
          },
        );
        if (result.error) {
          throw new Error(result.error.message);
        }
        return result;
      }
      throw new Error('Unable to get client secret');
    },
  });
};
