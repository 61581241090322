import { createSlice } from '@reduxjs/toolkit';

type AuthState = {
  drawerOpen: boolean;
};

const initialState: AuthState = {
  drawerOpen: false,
};

const summarySlice = createSlice({
  name: 'summary',
  initialState,
  reducers: {
    toggleDrawer: (state) => {
      state.drawerOpen = !state.drawerOpen;
    },
  },
});

export const { toggleDrawer } = summarySlice.actions;

export default summarySlice.reducer;
