import commonConstants from '../../constants/common.constant';
import { callAPIPost } from './commonAuthAPI';

export const getUserInfoAPI = (email: string) => {
  const apiName = commonConstants.API_NAME.USERS;
  const path = commonConstants.API_PATH.INFO;
  const body = {
    email,
  };
  return callAPIPost(body, apiName, path);
};

export const getUserTokenAPI = (email: string) => {
  const apiName = commonConstants.API_NAME.USERS;
  const path = commonConstants.API_PATH.CART_TOKEN_BY_EMAIL;
  const body = {
    email,
  };
  return callAPIPost(body, apiName, path);
};

export const updateUserCartTokenAPI = (email: string, cartToken: string) => {
  const apiName = commonConstants.API_NAME.USERS;
  const path = commonConstants.API_PATH.UPDATE_CART_TOKEN;
  const body = {
    email,
    cartToken,
  };
  return callAPIPost(body, apiName, path);
};
