import { Address } from '../redux/slices/authSlice';

export const CART_DETAIL = 'CART_DETAIL';
export const GET_CART_DETAILS = 'GET_CART_DETAILS';
export const GET_STRIPE_CLIENT_SECRET = 'GET_STRIPE_CLIENT_SECRET';
export const GET_STRIPE_PAYMENT_INTENT = 'GET_STRIPE_PAYMENT_INTENT';

const getQueryKey = {
  mainCartDetailQueryKey: (
    token: string | null,
    shippingAddress: Address | undefined,
    shippingZoneId: string | undefined,
  ): any[] => [CART_DETAIL, token, shippingAddress, { shippingZoneId }],
  getStripeClientSecretQueryKey: (payload: any): any[] => [
    GET_STRIPE_CLIENT_SECRET,
    payload,
  ],
  getStripePaymentIntentQueryKey: (payload: any): any[] => [
    GET_STRIPE_PAYMENT_INTENT,
    payload,
  ],
  getCartDetailsQueryKey: (payload: any): any[] => [GET_CART_DETAILS, payload],
};

export default getQueryKey;
