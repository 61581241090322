import { Button, styled, Typography } from '@mui/material';
import { themes } from '../../../theme';

export const Heading18 = styled(Typography)({
  fontSize: '18px',
  fontFamily: themes.light.fontLexendTera,
  fontWeight: 400,
});

// Font Size 16
export const Text16 = styled(Typography)({
  fontWeight: 400,
  fontSize: '16px',
  color: 'black',
});
export const Text16Gray = styled(Typography)({
  fontWeight: 400,
  fontSize: '16px',
  color: themes.light.colorGray,
});
export const Text16Weight500 = styled(Typography)({
  fontWeight: 500,
  fontSize: '16px',
  color: 'black',
});
export const Text16Weight500Gray = styled(Typography)({
  fontWeight: 500,
  fontSize: '16px',
  color: themes.light.colorGray,
});

// Font Size 14
export const Text14Weight500 = styled(Typography)({
  fontWeight: 500,
  fontSize: '14px',
  color: 'black',
});
export const Text14Weight500Gray = styled(Typography)({
  fontWeight: 500,
  fontSize: '14px',
  color: themes.light.colorGray,
});
export const Text14Weight500Blue = styled(Typography)({
  textAlign: 'center',
  fontWeight: 500,
  fontSize: '14px',
  color: themes.light.colorMaximumBlueGreen,
});

// Font Size 12
export const Text12Weight500Gray = styled(Typography)({
  fontWeight: 500,
  fontSize: '12px',
  color: themes.light.colorGray,
});
export const Text12Weight600 = styled(Typography)({
  fontWeight: 600,
  fontSize: '12px',
  color: 'black',
});

export const TextButton = styled(Button)({
  display: 'flex',
  textTransform: 'unset',
  fontSize: '1rem',
});

export const CommonTitleText = styled(Text16)({
  textAlign: 'center',
  fontFamily: themes.light.fontLexendTera,
  letterSpacing: '-0.05em',
});

export const CommonSubtext = styled(Typography)({
  textAlign: 'center',
  fontWeight: 400,
  fontSize: '1rem',
  color: themes.light.colorGray,
  textTransform: 'none',
});
