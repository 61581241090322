const locales = 'en-US';

const DEFAULT_CURRENCY = 'USD';

const defaultCurrencyFormatter = (currency: string = DEFAULT_CURRENCY) =>
  new Intl.NumberFormat(locales, {
    currency,
    style: 'currency',
  });

const NUMBER_FORMATTER = new Intl.NumberFormat(locales);

const COMPACT_NUMBER_FORMATTER = new Intl.NumberFormat(locales, {
  notation: 'compact',
});

const formatUtil = {
  convertObjectIntoQueryString: (object: any = {}): string => {
    return Object.keys(object)
      .filter(
        (key) =>
          object[key] !== undefined &&
          object[key] !== null &&
          object[key] !== '',
      )
      .map((key) => `${key}=${encodeURI(object[key])}`)
      .join('&');
  },
  formatCurrency: (num: number, currency?: string) => {
    return defaultCurrencyFormatter(currency).format(num);
  },
  formatNumber: (num: number) => {
    return NUMBER_FORMATTER.format(num);
  },
  formatCompactNumber: (num: number) => {
    // 100000 => 100k, 10000000 => 10M
    return COMPACT_NUMBER_FORMATTER.format(num);
  },
  divideTo100AndFormatCurrency: (num: number, currency?: string) => {
    return defaultCurrencyFormatter(currency).format(+(num / 100).toFixed(2));
  },
};

export default formatUtil;
