import { createSlice } from '@reduxjs/toolkit';

export type ShippingMethodState = {
  shippingZonesname: string;
  shippingZonesprice: string;
  shipping_zone_id: string;
  transitTime: string;
  price: {
    currencyCode: string;
    displayPrice: number;
  };
  rules?: {
    rule_name: string;
    price: number;
  } | null;
};

const initialState: ShippingMethodState | null = null;

const shippingMethodSlice = createSlice({
  name: 'shippingMethod',
  initialState,
  reducers: {
    updateShippingMethod: (state, action) => {
      return action.payload;
    },
    removeShippingMethod: () => {
      return null;
    },
  },
});

export const { updateShippingMethod, removeShippingMethod } =
  shippingMethodSlice.actions;

export default shippingMethodSlice.reducer;
