import { KeyboardArrowRight } from '@mui/icons-material';
import { Accordion, Box, styled } from '@mui/material';
import { StackHorizontal } from '../../components/Common/Styled/CommonStyled';
import { themes } from '../../theme';

export const SummaryAccordion = styled(Accordion)({
  boxShadow: 'none',
  background: themes.light.backgroundColorAlabaster,
});

export const InfoContainer = styled(Box)({
  margin: '20px 0',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const RightIcon = styled(KeyboardArrowRight)({
  margin: '20px 0',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '36px',
  height: '36px',
  color: themes.light.colorGray,
});

export const HeaderContainer = styled(StackHorizontal)(({ theme }) => ({
  cursor: 'pointer',
  padding: 0,
  margin: 0,
  minHeight: 56,
  [theme.breakpoints.up('md')]: {
    minHeight: 64,
  },
}));

export const ContainerPaymentElement = styled(Box)({
  border: `1px solid ${themes.light.colorBlack}`,
  padding: '1rem',
  borderRadius: '4px',
  width: '100%',
});
