import { useNavigate } from 'react-router-dom';
const History = {
  navigate: null,
  push: (page: string) => {
    //@ts-ignore
    return History.navigate(page);
  },
};

export default History;

export const NavigateSetter = () => {
  //@ts-ignore
  History.navigate = useNavigate();
  return null;
};
