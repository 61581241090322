import React, { Suspense } from 'react';
import { MixpanelProvider } from 'react-mixpanel-browser';
import './utils/mobile-login';
import 'react-toastify/dist/ReactToastify.css';
import loadable from '@loadable/component';
import { ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Amplify, Auth } from 'aws-amplify';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import amplifyConfig from './aws-exports';
import CenterLoader from './components/Common/CenterLoader/CenterLoader';
import ToastMessage, {
  ToastType,
} from './components/Common/ToastMessage/ToastMessage';
import LayoutWithSummaryDrawer from './components/Layout/LayoutWithSummaryDrawer';
import commonConstants from './constants/common.constant';
import { getIsSilentLoading } from './redux/selectors/auth.selector';
import { logout } from './redux/slices/authSlice';
import store, { persistor } from './redux/store';
import { themeMaterial } from './theme';
import { NavigateSetter } from './utils/history.util';
import UnderMaintenance from './pages/UnderMaintenance/UnderMaintenance';

const PageNotFound = loadable(
  () => import('./pages/PageNotFound/PageNotFound'),
);
const LayoutWithMobileContainer = loadable(
  () => import('./components/Layout/LayoutWithMobileContainer'),
);
const Summary = loadable(() => import('./pages/Summary/Summary'));
const TransitionPage = loadable(
  () => import('./pages/TransitionPage/TransitionPage'),
);
const RedirectLinkByReferral = loadable(
  () => import('./pages/RedirectLinkByReferral/RedirectLinkByReferral'),
);

Amplify.configure(amplifyConfig);

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

// [OPTIONAL] Custom options to pass to `mixpanel.init()`.
const MIXPANEL_CONFIG = {
  // track_pageview: true, // Set to `false` by default
};

const checkExpiredAndLogout = async (error: any) => {
  // Check Expired Time here to logout;
  try {
    await Auth.currentSession();
  } catch (err: any) {
    if (store.getState()?.auth?.user) {
      queryClient.invalidateQueries();
      persistor.purge();
      store.dispatch(logout());
      toast(
        <ToastMessage
          text={commonConstants.EXPIRED_SESSION_MESSAGE}
          type={ToastType.ERROR.type}
        />,
      );
    }
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        // Fallback Error Catch If we don't define onError when using useQuery
        toast(
          <ToastMessage
            text={
              error?.response?.data?.message ??
              error?.message ??
              commonConstants.SOMETHING_WENT_WRONG
            }
            type={ToastType.ERROR.type}
          />,
        );
      },
    },
    mutations: {
      retry: false,
      onError: (error: any) => {
        // Fallback Error Catch If we don't define onError when using useMutate
        toast(
          <ToastMessage
            text={
              error?.response?.data?.message ??
              error?.message ??
              commonConstants.SOMETHING_WENT_WRONG
            }
            type={ToastType.ERROR.type}
          />,
        );
      },
    },
  },
  queryCache: new QueryCache({
    onError: checkExpiredAndLogout,
  }),
  mutationCache: new MutationCache({
    onError: checkExpiredAndLogout,
  }),
});

function App() {
  const isSilentLoading = useSelector(getIsSilentLoading);
  if (isSilentLoading) {
    return <CenterLoader />;
  }
  return (
    <MixpanelProvider config={MIXPANEL_CONFIG} token={MIXPANEL_TOKEN}>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ReactQueryDevtools />
          <HelmetProvider>
            <Helmet>
              <title>iRefer Checkout</title>
            </Helmet>
            <ThemeProvider theme={themeMaterial}>
              <BrowserRouter>
                <NavigateSetter />
                <Suspense fallback={<></>}>
                  <Routes>
                    <Route
                      path="/referral/:code"
                      element={<RedirectLinkByReferral />}
                    />
                    <Route element={<LayoutWithMobileContainer />}>
                      <Route element={<Outlet />}>
                        <Route
                          path="transition-page"
                          element={<TransitionPage />}
                        />
                        <Route element={<LayoutWithSummaryDrawer />}>
                          <Route path="summary" element={<Summary />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route
                      path="/under-maintenance"
                      element={<UnderMaintenance />}
                    />
                    <Route path="*" element={<PageNotFound />} />
                  </Routes>
                </Suspense>
              </BrowserRouter>
              <ToastContainer
                className="toaster-container"
                position="top-center"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                closeButton={false}
              />
            </ThemeProvider>
          </HelmetProvider>
        </LocalizationProvider>
      </QueryClientProvider>
    </MixpanelProvider>
  );
}

export default App;
