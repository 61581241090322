import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  AppBar,
  Box,
  CircularProgress,
  Container,
  Divider,
  Drawer,
  Toolbar,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ItemList from '../../components/Common/ItemList/ItemList';
import { Text16Weight500 } from '../../components/Common/Styled/TypographyStyled';
import { getDrawerState } from '../../redux/selectors/summary.selector';
import { toggleDrawer } from '../../redux/slices/summarySlice';
import { themes } from '../../theme';
import { HeaderContainer } from './SummaryStyled';
import { useMemo } from 'react';
import formatUtil from '../../utils/format.util';
import StickyNavigationSection from '../../components/Common/StickyNavigationSection/StickyNavigationSection';
import IReferBanner from '../../components/Common/IReferBanner/IReferBanner';
import { useIsInIFrame } from '../../hooks/Common/useIsIFrame';

type SummaryDrawerProps = {
  cartData: CartDataResponse;
  isFetchingCartData: boolean;
};

const SummaryDrawer = ({
  cartData,
  isFetchingCartData,
}: SummaryDrawerProps) => {
  const decryptedData = cartData?.decryptedData;
  const drawerOpen = useSelector(getDrawerState);
  const dispatch = useDispatch();
  const { marginTopValueDependOnIFrame } = useIsInIFrame();

  const totalTitle = useMemo(() => {
    if (decryptedData?.amount) {
      return `${formatUtil.formatCurrency(
        +decryptedData?.amount,
        decryptedData?.currency,
      )} (${decryptedData?.items?.length} item${
        decryptedData?.items?.length > 1 ? 's' : ''
      })`;
    }
    return null;
  }, [decryptedData]);

  return (
    <AppBar
      position="fixed"
      sx={{ backgroundColor: themes.light.backgroundColorWhite }}
      elevation={0}
    >
      <Container maxWidth="sm">
        <IReferBanner />
        <Toolbar disableGutters>
          <SummaryHeader
            isFetchingCartData={isFetchingCartData}
            totalTitle={totalTitle}
          />
          <Drawer
            anchor={'top'}
            open={drawerOpen}
            onClose={() => dispatch(toggleDrawer())}
          >
            <Container maxWidth="sm">
              <StickyNavigationSection isBottom={false}>
                <IReferBanner />
                <SummaryHeader
                  isFetchingCartData={isFetchingCartData}
                  totalTitle={totalTitle}
                />
                <Divider variant="fullWidth" />
              </StickyNavigationSection>
              <Box mt={marginTopValueDependOnIFrame}>
                <ItemList
                  cartData={decryptedData}
                  isLoading={isFetchingCartData}
                />
              </Box>
            </Container>
          </Drawer>
        </Toolbar>
      </Container>
      <Divider variant="fullWidth" />
    </AppBar>
  );
};

type SummaryHeaderProps = {
  isFetchingCartData: boolean;
  totalTitle: string | null;
};

const SummaryHeader = ({
  isFetchingCartData,
  totalTitle,
}: SummaryHeaderProps) => {
  const dispatch = useDispatch();
  const drawerOpen = useSelector(getDrawerState);
  return (
    <Box sx={{ width: '100%' }}>
      <HeaderContainer onClick={() => dispatch(toggleDrawer())}>
        <Text16Weight500 sx={{ userSelect: 'none' }}>Summary</Text16Weight500>
        <Box display="flex" alignItems="center">
          {isFetchingCartData ? (
            <CircularProgress size={24} sx={{ marginRight: '10px' }} />
          ) : (
            <Text16Weight500 sx={{ userSelect: 'none' }}>
              {totalTitle}
            </Text16Weight500>
          )}
          {drawerOpen ? (
            <ExpandLess />
          ) : (
            <ExpandMore sx={{ color: themes.light.colorBlack }} />
          )}
        </Box>
      </HeaderContainer>
    </Box>
  );
};

export default SummaryDrawer;
