import { styled, Stack } from '@mui/material';
import complete_i_refer_logo from '../../../assets/images/irefer-complete-logo.png';
import i_refer_text_without_logo from '../../../assets/images/irefer-text-without-logo.png';
import { useIsInIFrame } from '../../../hooks/Common/useIsIFrame';

export const Logo = styled('img')({
  objectFit: 'contain',
});
export const CompleteLogo = styled(Logo)({
  width: '200px',
});
export const TextWithoutLogo = styled(Logo)({
  marginTop: 8,
  height: '100px',
});

const IReferBanner = () => {
  const { isInIFrame } = useIsInIFrame();
  return (
    <Stack justifyContent="center" alignItems="center" mt={1} gap={2}>
      {isInIFrame ? (
        <TextWithoutLogo src={i_refer_text_without_logo} />
      ) : (
        <CompleteLogo src={complete_i_refer_logo} />
      )}
    </Stack>
  );
};

export default IReferBanner;
