const commonConstants = {
  SOMETHING_WENT_WRONG: 'Something went wrong.',
  EXPIRED_SESSION_MESSAGE: 'Your session has expired. Please Sign in',
  USER: 'user',
  REFERRER: 'referrer',
  DEFAULT_HOME_NAVIGATION_PATH: '/summary',
  DIRECTION: {
    ASC: 'asc',
    DESC: 'desc',
  },
  API_NAME: {
    USERS: 'user',
    PAYMENT: 'payment',
  },
  API_PATH: {
    INFO: '/info',
    SHIPPING_INFO: '/shipping-info',
    LINK_CARD: '/link-card',
    CART: '/cart',
    CHECKOUT: '/checkout',
    CHECKOUT_V2: '/checkout-v2',
    REFERRAL_LINK: '/referral-link',
    CART_TOKEN_BY_EMAIL: '/cart-token-by-email',
    UPDATE_CART_TOKEN: '/update-cart-token',
  },
  LOCAL_STORAGE_KEY: {
    CART_TOKEN: 'cartToken',
  },
  DATE_FORMAT: {
    MM_DD_YYYY: 'MM/DD/YYYY',
  },
  TOKEN: 'token',
  MARGIN_TOP: {
    WITH_IREFER_LOGO: 36,
    WITHOUT_IREFER_LOGO: 24,
  },
  SOCIAL_MEDIA: {
    GOOGLE: 'google',
    APPLE: 'apple',
  } as const,
  RULE_NAMES: {
    MIN_AMOUNT: 'min_amount',
  },
};

export default commonConstants;
