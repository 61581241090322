import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserInfoAPI } from '../../hooks/Auth/authAPI';

export type PayloadActionLogin = {
  user: User;
  isSilentLogin: boolean;
};

export type User = {
  id: string;
  email: string;
  role: string;
  cog_id: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  lat?: string;
  long?: string;
  checkoutInfo: CheckoutInfoType;
};

export type PaymentInfoType = {
  last4: string;
  brand: string;
};

export type CheckoutInfoType = {
  paymentInfo: PaymentInfoType;
  shippingInfo: AddressState;
};

export type DetailInfo = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

export type Address = {
  addressLine1: string;
  addressLine2: string;
  postalCode: string;
  state: string;
  stateShortVersion: string;
  country: string;
  countryShortVersion: string;
  sameAsShipping?: boolean;
  lat?: number;
  lng?: number;
};

export type AddressState = {
  user_id?: string;
  detailInfo: DetailInfo;
  shippingAddress: Address;
  billingAddress: Address;
};

type AuthState = {
  isAuthenticatedCheckoutUser: boolean;
  user: User | null;
  isSilentLogin: boolean;
  isSilentLoading: boolean;
};

const initialState: AuthState = {
  isAuthenticatedCheckoutUser: false,
  user: null,
  isSilentLogin: false,
  isSilentLoading: false,
};

export const initialAddressState = (user: User): AddressState => {
  let detailInfo: DetailInfo = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  };
  if (user?.first_name) {
    detailInfo.firstName = user?.first_name;
  }
  if (user?.last_name) {
    detailInfo.lastName = user?.last_name;
  }
  if (user?.email) {
    detailInfo.email = user?.email;
  }
  if (user?.phone) {
    detailInfo.phone = user?.phone;
  }
  return {
    detailInfo,
    shippingAddress: {
      addressLine1: '',
      addressLine2: '',
      postalCode: '',
      state: '',
      stateShortVersion: '',
      country: '',
      countryShortVersion: '',
    },
    billingAddress: {
      addressLine1: '',
      addressLine2: '',
      postalCode: '',
      state: '',
      stateShortVersion: '',
      country: '',
      countryShortVersion: '',
      sameAsShipping: false,
    },
  };
};

export const fetchUserInfo = createAsyncThunk(
  'auth/fetchUserInfo',
  async ({ email, callbackFn }: { email: string; callbackFn?: Function }) => {
    try {
      const response = await getUserInfoAPI(email);
      if (callbackFn) {
        callbackFn();
      }
      return response.data;
    } catch (error) {
      return null;
    }
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state: any, action: PayloadAction<PayloadActionLogin>) => {
      state.isAuthenticatedCheckoutUser = true;
      state.user = action.payload.user;
      state.isSilentLogin = action.payload.isSilentLogin ?? false;
    },
    logout: (state: any) => {
      state.isAuthenticatedCheckoutUser = false;
      state.user = null;
      state.isSilentLogin = false;
    },
    setSilentLoading: (state: any, action: PayloadAction<boolean>) => {
      state.isSilentLoading = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
      state.user = action.payload;
    });
  },
});

export const { login, logout, setSilentLoading } = authSlice.actions;

export default authSlice.reducer;
