import { RootState } from '../store';

export const getIsAuthenticated = (state: RootState) =>
  state.auth?.isAuthenticatedCheckoutUser;

export const getUser = (state: RootState) => state.auth?.user;

export const getIsSilentLoading = (state: RootState) =>
  state.auth?.isSilentLoading;

export const getIsSilentLogin = (state: RootState) => state.auth?.isSilentLogin;
