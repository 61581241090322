import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import httpStatusCode from '../../constants/http-status-code.constant';
import axios from '../../utils/axios.util';
import { logout } from '../../redux/slices/authSlice';
import { toast } from 'react-toastify';
import ToastMessage, {
  ToastType,
} from '../../components/Common/ToastMessage/ToastMessage';

const useAxios = (name: string = '') => {
  const dispatch = useDispatch();
  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      (response: any) => response,
      (error: any) => {
        const errorMessage = error?.response?.data ?? {
          message: 'Something went wrong.',
        };
        if (error?.response?.status === httpStatusCode.UNAUTHENTICATED) {
          toast(
            <ToastMessage
              text={errorMessage.message}
              type={ToastType.ERROR.type}
            />,
          );
          dispatch(logout());
        }
        return Promise.reject(error);
      },
    );
    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [dispatch, name]);

  return axios;
};

export default useAxios;
