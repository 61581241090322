import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, styled } from '@mui/material';

import { themes } from '../../../theme';

export const Wrapper = styled(Stack)({
  height: '100vh',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: themes.light.backgroundColorAlabaster,
});

export const StyledBackground = styled(Box)({
  // minHeight: 'calc(100vh - 136px)',
  backgroundColor: themes.light.backgroundColorWhite,
});

export const RoundedButton = styled(Button)({
  textTransform: 'unset',
  minWidth: '110px',
});

export const CircleButton = styled(RoundedButton)({
  borderRadius: '24px',
});

export const StyledLoadingButton = styled(LoadingButton)({
  height: '52px',
  textTransform: 'unset',
  backgroundColor: themes.light.backgroundColorBlack,
  '&:hover': {
    backgroundColor: themes.light.backgroundColorBlack,
  },
  '&:disabled': {
    backgroundColor: themes.light.backgroundColorDisabled,
    color: themes.light.colorWhite,
  },
});

export const StyledOutlinedLoadingButton = styled(StyledLoadingButton)({
  color: themes.light.colorBlack,
  backgroundColor: themes.light.backgroundColorWhite,
  border: `1px solid ${themes.light.colorBlack}`,
  '&:hover': {
    backgroundColor: themes.light.backgroundColorWhite,
  },
});

export const StackHorizontal = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '10px',
});

export const CenterBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
